import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
// import bgImage from "../images/contact-bg.jpg"
import ContactModal from "../components/contact-modal"
import HubspotMeeting from "../components/hubspot-meeting"

const ContactPage = () => {
  // useEffect(() => {
  //   // const head = document.querySelector("head")
  //   // const script = document.createElement("script")
  //   // script.setAttribute(
  //   //   "src",
  //   //   "https://assets.calendly.com/assets/external/widget.js"
  //   // )
  //   // head.appendChild(script)
  //   const bb = document.querySelector("iframe").contentWindow //.querySelector(".booking-layout") //.style.background = "white"
  //   console.log("booking-layout", bb)
  // })
  return (
    <Layout headerFixed={false} page="Contact">
      <SEO title="Contact LUCA+ Electronic Invoicing Australia | Connects (XERO, MYOB, QuickBooks)" description="Get in touch with LUCA+ to learn about e-invoicing platform that automatically populates invoices directly into your accounting platform." />

      <section className="expanded contact-page">
        <div className="contact-page__banner">
          <div className="contact-page__overlay"></div>
        </div>
        <div className="container">
          <div className="contact-page__modal-wrapper">
            <ContactModal />
          </div>
          <div className="contact-page__form">
            <h1 className="underline-green2">Book a Free Demo</h1>
            <HubspotMeeting />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage
