import React from "react"

const HubspotMeeting = () => {
  return (
    <div
      className="calendly-inline-widget"
      style={{ maxWidth: 1200, height: 820 }}
    >
      <iframe
        src="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
        width="100%"
        height="100%"
        frameBorder="0"
      >
        <style
          dangerouslySetInnerHTML={{
            __html: `
      .booking-layout { background-color: #fff !important; }
    `,
          }}
        />
      </iframe>
    </div>
  )
}

export default HubspotMeeting
